
// import { StaticImage } from "gatsby-plugin-image";
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo";
import page_404_img from '../assets/404/404.png';
import Navbar from "../components/navbar";
import '../components/layout.scss';

const imgStyle = {
  width:'50%'
}

const returnTagStyle = {
  color: '#fff',
    background: '#006df6',
    padding: '10px 20px',
    borderRadius: '10px'
}

const NotFoundPage = () => (
  // <Layout></Layout>
  <>
  <Navbar />
  <div style={{textAlign:'center'}}>
  <Seo title="404: Not found" />
    <div className="container text-center my-5">
      {/* <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
      <div>
      <img src={page_404_img} alt={''} style={imgStyle} />
      </div>
      <div>
      <a href="/" style={returnTagStyle}>返回首页</a>
      </div>
    </div>
  </div>
  </>
)

export default NotFoundPage
